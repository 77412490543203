<template>
	<div class="ns-rebate">
		<el-carousel max-height="400px" v-loading="loadingAd">
			<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
		</el-carousel>

		<!-- 商品列表 -->
		<div class="ns-rebate-box" v-loading="loading">
            <div class="ns-rebate-title" v-if="goodsList.length">
				<i class="iconfont icontuangou"></i>
				<span>限时折扣</span>
			</div>
			<div class="goods-list" v-if="goodsList.length">
				<div class="item" v-for="item in goodsList" :key="item.id">
					<div class="goods" style="position: relative;" @click="$router.pushToTab({ path: '/product-' + item.goods_id + '.html' })">
						<!-- 自定义标签 -->
						<div class="goods-diy diy-discount" v-if="item.price_label">
							<span>{{item.price_label}}</span>
						</div>
						<div class="goods-diy diy-stock" :style="{background:item.label_color}" v-if="item.stock_label">
							<span>{{item.stock_label}}</span>
						</div>
						<div class="goods-left-diy diy-own" v-if="item.own_label">
							<span><i class="iconfont icon-sandian"></i>{{item.own_label}}</span>
						</div>
						
						<!-- 商品图片区 -->
						<div class="img"><el-image fit="scale-down" :src="$img(item.sku_image)" lazy @error="imageError(index)"></el-image></div>

						<!-- 商品名称 -->
						<div class="name">
							<p :title="item.goods_name" v-if="current_lang">
								<router-link :to="'/product-' + item.goods_id + '.html?lang='+current_lang" :title="item.goods_name">{{ item.goods_name }}</router-link>
							</p>
							<p :title="item.goods_name" v-else>
								<router-link :to="'/product-' + item.goods_id + '.html'" :title="item.goods_name">{{ item.goods_name }}</router-link>
							</p>
						</div>

						<!-- 价格展示区 -->
						<div class="price">
							<!-- 价格 -->
							<div>
								<p>
									<span>折扣价</span>
									<span>{{ siteInfo.price_unit }}</span>
									<span class="main_price">{{ item.discount_price }}</span>
								</p>
								<span class="primary_price">{{ siteInfo.price_unit }}{{ item.price }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else><div class="ns-text-align">暂无正在进行折扣的商品，去首页看看吧</div></div>

			<div class="pager">
				<el-pagination
					background
					:pager-count="5"
					:total="total"
					:prev-text="$lang('common.prevtext')" 
					:next-text="$lang('common.nexttext')" 
					:current-page.sync="currentPage"
					:page-size.sync="pageSize"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange"
					hide-on-single-page
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { goodsPage } from '@/api/rebate';
import { mapGetters } from 'vuex';
import { adList } from '@/api/website';

export default {
	name: 'rebate',
	components: {},
	data: () => {
		return {
			loading: true,
			goodsList: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			loadingAd: true,
			current_lang: '',
			adList: []
		};
	},
	created() {
		this.current_lang = this.$route.query.lang || ""
		this.getAdList();
		this.getGoodsList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage', 'addonIsExit', 'siteInfo'])
	},
	watch: {
		
	},
	methods: {
		getAdList() {
			adList({ keyword: 'NS_PC_GROUPBUY' }).then(res => {
				this.adList = res.data.adv_list;
				for (let i = 0; i < this.adList.length; i++) {
					if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
				}
				this.loadingAd = false;
			}).catch(err => {
				this.loadingAd = false;
			});
		},
		/**
		 * 折扣商品
		 */
		getGoodsList() {
			goodsPage({
				page_size: this.pageSize,
				page: this.currentPage
			}).then(res => {
                this.goodsList = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.$message.error(err.message);
            });
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getGoodsList();
		},
		/**
		 * 图片加载失败
		 */
		imageError(index) {
			this.goodsList[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss" scoped>
.el-main {width: 100%; overflow: hidden;}
.ns-rebate {
	background: #ffffff;
	.ns-rebate-box {
		padding-top: 54px;
		width: $width;
		margin: 0 auto;

		.ns-rebate-title {
			width: 100%;
			border-bottom: 1px solid $base-color;
			padding-bottom: 10px;

			i {
				font-size: 32px;
				color: $base-color;
			}

			span {
				font-size: 30px;
				font-family: 'BDZongYi-A001';
				font-weight: 600;
				color: $base-color;
				margin-left: 15px;
			}
		}
	}
	.goods-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-top: 45px;

		.item {
			width: 20%;
			padding: 0 6px;
			box-sizing: border-box;
			margin-bottom: 16px;

			.goods {
				width: 100%;
				border: 1px solid #e9e9e9;
				background-color: #ffffff;
				overflow: hidden;
				color: #303133;
				transition: 0.3s;
				padding: 10px;
				box-sizing: border-box;
				cursor: pointer;
			}
		}

		.img {
			width: 100%;
			height: 100%;

			.el-image {
				width: 100%;
				height: 208px;
				.el-image__error {
					width: 100%;
					height: 100%;
				}
			}
		}

		.price {
			p {
				display: flex;
				align-items: flex-end;
				height: 24px;
				color: $base-color;
				margin: 10px 0 5px;

				span:first-child {
					font-size: 20px;
					font-family: 'BDZongYi-A001';
					font-weight: 600;
					margin-right: 5px;
					line-height: 24px;
				}
				span:nth-child(2) {
					line-height: 14px;
				}
			}

			.main_price {
				color: $base-color;
				font-size: 25px;
				line-height: 24px;
				font-weight: 500;
			}

			.primary_price {
				text-decoration: line-through;
				color: $base-color-info;
				margin-left: 5px;
			}
		}

		.name {
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 5px;
			white-space: normal;
			overflow: hidden;
			
			p {
				line-height: 24px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				height: 50px;
			}
		}
	}
}
</style>
<style lang="scss">
.ns-rebate {
	.el-carousel {
		.el-carousel__container {
			height: 400px;
		}
		.el-image__inner {
			width: auto;
		}
	}
	.el-carousel__arrow--right{
		right: 60px;
	}
}

.goods-diy{
	position: absolute;
	right: 0;
	line-height: 25px;
	padding: 2px 5px;
	height: 25px;
	min-width: 40px;
	z-index: 1;
	// border-top-left-radius: 15px;
	// border-bottom-left-radius: 15px;
	text-align: center;
	color: #fff;
}
// 折扣标签
.diy-discount {	
	top: 10px;
	background-color: #F54F3D;
	
	span{
		letter-spacing:1px;
		// padding-left: 4px;
		font-size: 14px;
	}
}

.diy-stock {	
	top: 50px;
	background-color: #75D69C;
	
	span{
		letter-spacing:1px;
		padding-left: 4px;
		font-size: 14px;
	}
}

.goods-left-diy {
	position: absolute;
	left: 0;
	top: 0;
	line-height: 25px;
	padding: 2px 5px 2px 2px;
	height: 25px;
	width: 50px;
	z-index: 1;
	border-radius: 0px 0px 8px;
	text-align: center;
	color: #fff;
}

.diy-own {
	background-color: #EA4D1A;
	
	span{
		letter-spacing:1px;
		font-size: 14px;
		.iconfont {
			font-size: 14px;
		}
	}
}
</style>
